<div class="search mb-3">
  <rb-form-input
    class="white w-90 mb-0"
    [label]="label"
    [(ngModel)]="searchTerm"
    (ngModelChange)="search($event)"
    [placeholder]="placeholder"
    #formEl="ngModel"
  >
    <ng-template rbFormValidationMessage="autocomplete">
      {{ formEl.getError('autocomplete') }}
    </ng-template>
  </rb-form-input>
  <a href="#" title="{{ 'clearSearch' | translate }}" *ngIf="searchTerm" (click)="clearSearch()">
    <span class="rb-ic rb-ic-lg rb-ic-close mr-3"></span>
  </a>
  <span *ngIf="!searchTerm" class="rb-ic rb-ic-lg rb-ic-search mr-3"></span>
</div>
