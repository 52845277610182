import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {
  @Output()
  searchChange = new EventEmitter<string>();

  @Input()
  label = '';

  @Input()
  placeholder = '';

  @Input()
  set initialSearchTerm(value: string) {
    if (value) {
      this.searchTerm = value;
      this.searchChange.emit(this.searchTerm);
    }
  }
  searchTerm = '';

  private searchDebounce$ = new Subject<null>();

  ngOnInit(): void {
    this.addSearchDebounce();
  }

  /**
   * prevent too many search requests while typing
   */
  addSearchDebounce(): void {
    this.searchDebounce$.pipe(debounceTime(300)).subscribe(() => {
      this.searchChange.emit(this.searchTerm);
    });
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm.trim();
    this.searchDebounce$.next(null);
  }

  clearSearch(): void {
    this.search('');
  }
}
